import { Menu } from '@headlessui/react'
import IonIcon from '@reacticons/ionicons'
import cn from 'classnames'
import React, { useEffect, useState } from 'react'
import { MouseEvent } from 'react'
import { LabelLink } from 'src/components/global/LabelLink'
import { useAuthenticated } from 'src/hooks/useAuthenticated'

import { PremiumModal } from '../global/PremiumModal'

const FirstComponent = ({ menuOpen, setMenu, openModel }: { menuOpen: boolean; setMenu: any; openModel: any }) => {
  const { isPremium } = useAuthenticated()

  const handleClick = (e: MouseEvent<HTMLAnchorElement>) => {
    if (!isPremium) {
      e.preventDefault() // Prevent the navigation
      //router.push('/login') // Redirect to login
      //setShowModal(true)
      openModel(true)
    }
    handleMenuItemClick()
  }

  const handleMenuItemClick = () => {
    setMenu(!menuOpen)
  }

  return (
    menuOpen && (
      <Menu.Items
        static
        className="z-30 flex flex-row w-full  rounded-[10px] shadow-lg bg-white absolute"
        style={{ top: '40px', width: 'auto' }}
      >
        <div className="py-2 w-full">
          <ul>
            <li className="px-2 w-full">
              <LabelLink
                href="/graveyard-consolidations/?tab=consolidations"
                className="font-secondary font-medium text-[0.875rem] text-left px-3 leading-9 block w-full items-center h-full text-textdark bg-white rounded-[10px] hover:bg-turquoise hover:bg-opacity-10"
                label="Consolidations"
                onClick={handleClick}
              />
            </li>

            <li className="px-2 w-full">
              <LabelLink
                href="/graveyard-consolidations/?tab=graveyard"
                className="font-secondary font-medium text-[0.875rem] text-left px-3 leading-9 block w-full items-center h-full text-textdark bg-white rounded-[10px] hover:bg-turquoise hover:bg-opacity-10"
                label="Graveyard"
                onClick={handleClick}
              />
            </li>
          </ul>
        </div>
      </Menu.Items>
    )
  )
}

interface MenuProps {
  isOpen: boolean
  onClosePanel: any
}
export const IndustryAnalysisMenu: React.FC<MenuProps> = ({ isOpen, onClosePanel }) => {
  const [showModal, setShowModal] = useState(false)
  const [disabledMenuOpen, setDisabledMenuOpen] = useState(false)
  const [menuOpen, setMenuOpen] = useState(isOpen)

  const handleCloseModal = () => {
    setShowModal(false)
    setDisabledMenuOpen(true)
  }
  const setMenu = (status: any) => {
    setMenuOpen(status)
  }
  const openModel = () => {
    setShowModal(true)
  }

  useEffect(() => {
    // eslint-disable-next-line no-console
    if (!disabledMenuOpen) {
      setMenuOpen(isOpen)
    } else {
      setDisabledMenuOpen(false)
      onClosePanel()
    }
  }, [isOpen])

  return (
    <>
      <Menu as="div" className={cn('relative inline-block h-full text-nowrap cursor-pointer text-lef')}>
        {({ open }) => (
          <>
            <Menu.Button
              type="button"
              className={cn(
                'inline-flex justify-center gap-1 items-center w-full text-white font-secondary text-[0.875rem] h-full font-bold',
                { 'bg-white bg-opacity-5': open },
              )}
              onClick={() => setMenuOpen(!menuOpen)}
            >
              Industry Analysis
              <IonIcon
                className="text-white mr-0 !important"
                name={menuOpen ? 'chevron-up-outline' : 'chevron-down-outline'}
              />
            </Menu.Button>
            <FirstComponent menuOpen={menuOpen} setMenu={setMenu} openModel={openModel} />
            {/* {open && (
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items
                  static
                  className=" overflow-hidden flex flex-row w-[180px] h-[8rem] rounded-[1.25rem] shadow-lg bg-white absolute"
                  style={{ top: '60px' }}
                >
                  <div className="absolute py-4 w-[180px] h-full overflow-y-auto">
                    <ul>
                      <li className="px-3 h-12 w-full">
                        <LabelLink
                          href="/graveyard-consolidations/?tab=consolidations"
                          className="font-secondary font-medium text-[0.875rem] text-left px-6 w-full inline-flex items-center h-full text-textdark bg-white rounded-full hover:bg-turquoise hover:bg-opacity-10"
                          label="Consolidations"
                          onClick={handleClick}
                        />
                      </li>

                      <li className="px-3 h-12 w-full">
                        <LabelLink
                          href="/graveyard-consolidations/?tab=graveyard"
                          className="font-secondary font-medium text-[0.875rem] text-left px-6 w-full inline-flex items-center h-full text-textdark bg-white rounded-full hover:bg-turquoise hover:bg-opacity-10"
                          label="Graveyard"
                          onClick={handleClick}
                        />
                      </li>
                    </ul>
                  </div>
                </Menu.Items>
              </Transition>
            )} */}
          </>
        )}
      </Menu>
      <PremiumModal origin="/graveyard-consolidations/" isOpen={showModal} onClose={handleCloseModal} />
    </>
  )
}
